import FileMeta from './file-meta';
import FileProcessor from './file-processor';
import { DifferentChunkError,
	FileAlreadyUploadedError,
	UrlNotFoundError,
	UploadFailedError,
	UnknownResponseError,
	MissingOptionsError,
	UploadIncompleteError,
	InvalidChunkSizeError,
	UploadAlreadyFinishedError,
	UploadNotResumable } from './errors';

const MIN_CHUNK_SIZE = 262144;

type UploadArguments = {
	chunkSize?: number,
	storage?: Storage,
	contentType?: string,
	onChunkUpload?: ( info: {
				totalBytes: number
				uploadedBytes: number
				chunkIndex: number
				chunkLength: number
			} ) => void,
	id: string,
	url: string,
	file: File,
}

type UploadOptions = {
	chunkSize: number,
	storage: Storage,
	contentType: string,
	onChunkUpload: ( info: {
				totalBytes: number
				uploadedBytes: number
				chunkIndex: number
				chunkLength: number
			} ) => void,
	id: string,
	url: string,
	file: File,
}

export default class Upload {
	opts: UploadOptions;

	meta: FileMeta;

	processor: FileProcessor;

	lastResult: Response | null;

	finished = false;

	constructor( args: UploadArguments, allowSmallChunks: boolean ) {
		const opts = {
			chunkSize: MIN_CHUNK_SIZE,
			storage: window.localStorage,
			contentType: 'text/plain',
			onChunkUpload: () => {
				return;
			},
			...args,
		};

		if ( ( 0 !== opts.chunkSize % MIN_CHUNK_SIZE || 0 === opts.chunkSize ) && !allowSmallChunks ) {
			throw InvalidChunkSizeError( opts.chunkSize );
		}

		if ( !opts.id || !opts.url || !opts.file ) {
			throw MissingOptionsError();
		}


		this.opts = opts;
		this.meta = new FileMeta( opts.id, opts.file.size, opts.chunkSize, opts.storage );
		this.processor = new FileProcessor( opts.file, opts.chunkSize );
		this.lastResult = null;
	}

	async start(): Promise<Response|null> {
		const meta = this.meta;
		const processor = this.processor;
		const opts = this.opts;
		const finished = this.finished;

		const resumeUpload = async() => {
			const localResumeIndex = meta.getResumeIndex();
			const remoteResumeIndex = await getRemoteResumeIndex();

			const resumeIndex = Math.min( localResumeIndex, remoteResumeIndex );

			try {
				await processor.run( validateChunk, 0, resumeIndex );
			} catch {

				await processor.run( uploadChunk );

				return;
			}


			await processor.run( uploadChunk, resumeIndex );
		};

		const uploadChunk = async( checksum: string, index: number, chunk: ArrayBuffer ): Promise<void> => {
			const total = opts.file.size;
			const start = index * opts.chunkSize;
			const end = index * opts.chunkSize + chunk.byteLength - 1;

			const headers = {
				'Content-Type': opts.contentType,
				'Content-Range': `bytes ${start}-${end}/${total}`,
			};

			const res = await fetch( opts.url, {
				method: 'PUT',
				headers: headers,
				body: chunk,
			} );

			this.lastResult = res;
			checkResponseStatus( res, opts, [
				200,
				201,
				308,
			] );

			meta.addChecksum( index, checksum );

			opts.onChunkUpload( {
				totalBytes: total,
				uploadedBytes: end + 1,
				chunkIndex: index,
				chunkLength: chunk.byteLength,
			} );
		};

		const validateChunk = async( newChecksum: string, index: number ) => {
			const originalChecksum = meta.getChecksum( index );
			const isChunkValid = originalChecksum === newChecksum;
			if ( !isChunkValid ) {
				meta.reset();
				throw DifferentChunkError( index );
			}
		};

		const getRemoteResumeIndex = async(): Promise<number> => {
			const headers = {
				'Content-Range': `bytes */${opts.file.size}`,
			};

			const res = await fetch( opts.url, {
				method: 'PUT',
				headers: headers,
				body: null,
			} );

			checkResponseStatus( res, opts, [
				308,
			] );

			const header = res.headers.get( 'Range' ) || '';

			const range = header.match( /(\d+?)-(\d+?)$/ );
			if ( range ) {
				const bytesReceived = parseInt( range[2], 10 ) + 1;

				return Math.floor( bytesReceived / opts.chunkSize );
			}

			throw UploadNotResumable();
		};

		if ( finished ) {
			throw UploadAlreadyFinishedError();
		}

		if ( meta.isResumable() && meta.getFileSize() === opts.file.size ) {

			await resumeUpload();
		} else {

			await processor.run( uploadChunk );
		}
		meta.reset();
		this.finished = true;

		return this.lastResult;
	}

	pause(): void {
		this.processor.pause();
	}

	unpause(): void {
		this.processor.unpause();
	}

	cancel(): void {
		this.processor.pause();
		this.meta.reset();
	}
}

function checkResponseStatus( res: Response, opts: UploadOptions, allowed: Array<number> = [] ) {
	const status = res.status;
	if ( -1 < allowed.indexOf( status ) ) {
		return true;
	}

	switch ( status ) {
		case 308:
			throw UploadIncompleteError();

		case 201:
		case 200:
			throw FileAlreadyUploadedError( opts.id, opts.url );

		case 404:
			throw UrlNotFoundError( opts.url );

		case 500:
		case 502:
		case 503:
		case 504:
			throw UploadFailedError( status );

		default:
			throw UnknownResponseError();
	}
}
